import { HttpClient } from '../common';
import jwtDecode from 'jwt-decode';
import debounce from 'lodash-es/debounce';
import { isMatch } from 'matcher';
import ClientOAuth2 from 'client-oauth2';

export enum SecurityPolicyMode {
  None = 'None',
  Single = 'Single',
  Identity = 'Identity',
  Integrated = 'Integrated',
  OpenId = 'OpenId',
  SAML = 'SAML'
}

export enum Permission {
  Deny = 0,
  Show = 1,
  Modify = 2
}

export enum AuthorizeResult {
  Deny = 0,
  Allow = 1,
  LoginRequired = 2
}

export enum SSOVerifier {
  oidc = 'oidc',
  jwks = 'jwks'
}

export enum SSOAuthFlow {
  code = 'code',
  token = 'token'
}

export enum SSOAuthType {
  basic = 'basic',
  AD = 'AD'
}

export type OpenIdConfig = {
  issuer: string;
  verifierType: string;
  verifierUri: string;
  groups: string[];
  tenant: string;
  clientId: string;
  clientSecret: string;
  tokenUri?: string;
  profileUri?: string;
  authUri?: string;
  logoutUri?: string;
  verificationEnabled?: boolean;
  scope: string;
  authType?: string;
  authFlow?: 'token' | 'code';
  jwksUri?: string;
  claimsMap?: Record<string, string>;
};

export type IntegratedConfig = {
  autoRedirect: boolean;
};

export type SamlConfig = {
  autoRedirect: boolean;
};

export type SecurityPolicy =
  // OpenId
  | {
      mode: SecurityPolicyMode.OpenId;
      label?: string;
      group?: string;
      config: OpenIdConfig;
    }
  // Integrated
  | {
      mode: SecurityPolicyMode.Integrated;
      label?: string;
      config: IntegratedConfig;
    }
  // Identity
  | {
      mode: SecurityPolicyMode.Identity;
      label?: string;
      group?: string;
      config?: unknown;
    }
  // None
  | {
      mode: SecurityPolicyMode.None;
      label?: string;
      config?: unknown;
    }
  // Single
  | {
      mode: SecurityPolicyMode.Single;
      label?: string;
      config?: unknown;
    }
  // Saml
  | {
      mode: SecurityPolicyMode.SAML;
      label?: string;
      config: SamlConfig;
    };

export type RoleContext = {
  metaData: Record<string, unknown>;
  permissions: Record<string, Permission>;
};

export type UserContext = {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
  roles: Record<string, RoleContext>;
};

export type AuthorizeAction = {
  policy: string;
  roles?: string[];
  permissions?:
    | Record<string, keyof typeof Permission>
    | Array<Record<string, keyof typeof Permission>>;
};

export type UserContextPolicies = Record<string, UserContext | undefined | null>;

export type LoginCredentials = {
  username: string;
  password: string;
  policy: string;
};

export class EBAuth {
  public userContextPolicies: UserContextPolicies | null = null;
  private tokenExpiredPoliciesState: Record<string, unknown> = {};

  constructor(
    private httpClient: HttpClient,
    public securityPolicies: Record<string, SecurityPolicy>
  ) {}

  get tokenExpiredPolices(): string[] {
    return Object.keys(this.tokenExpiredPoliciesState);
  }

  async ssoAuth(ssoPolicy: string, ssoPath: string): Promise<false | string> {
    const qsParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const policy = this.securityPolicies[ssoPolicy];

    if (policy.mode !== SecurityPolicyMode.OpenId) {
      return false;
    }

    const openIdConfig = policy.config;
    const params = new URLSearchParams(openIdConfig.authFlow === 'code' ? qsParams : hashParams);

    if (params.get('error')) {
      const errorTitle = params.get('error');
      const errorDescription = params.get('error_description') ?? '';
      const errorUri = params.get('error_uri') ?? '';
      const displayedErrorUri = errorUri ? `[${errorUri}]` : '';

      window.EB.emitter.emit('eb-alert', {
        type: 'error',
        message: `
          Single Sign On Error:
          ${errorTitle} - ${errorDescription}${displayedErrorUri}
        `
      });

      return false;
    }

    try {
      const userAuth = this.getOpenIdClientOAuth(ssoPolicy, openIdConfig, ssoPath);
      const user = await userAuth[openIdConfig.authFlow ?? 'token'].getToken(window.location.href);

      const { accessToken, refreshToken, data } = user;
      const idToken = data['id_token'];

      // validate accessToken
      const { exp } = jwtDecode<{ exp: number }>(accessToken);

      this.httpClient.updateAuthTokensForPolicies(
        ssoPolicy,
        accessToken,
        refreshToken,
        exp,
        idToken
      );

      delete this.tokenExpiredPoliciesState[ssoPolicy];

      return ssoPath;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      window.EB.emitter.emit('eb-alert', {
        type: 'error',
        message: error.body
          ? `
            Single Sign On Error:
            ${error.body.error} - ${error.body.error_description}
          `
          : 'Single Sign On Error'
      });

      return false;
    }
  }

  async detectSSO(): Promise<false | string> {
    const qsParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const ssoPath = qsParams?.get('sso_path') || hashParams?.get('sso_path');
    const ssoPolicy = qsParams?.get('sso_policy') || hashParams?.get('sso_policy');

    if (!ssoPath || !ssoPolicy) {
      return Promise.resolve(false);
    }

    return this.ssoAuth(ssoPolicy, ssoPath);
  }

  async getUserContextPolicies(): Promise<UserContextPolicies> {
    this.userContextPolicies = await this.httpClient.get<UserContextPolicies>(`/v1/auth/current`);

    const integratedPolicy = Object.entries(this.securityPolicies).find(this.isIntegratedPolicy);

    if (
      this.isIntegratedPolicy(integratedPolicy) &&
      integratedPolicy[1].config.autoRedirect &&
      this.userContextPolicies[integratedPolicy[0]] == null
    ) {
      this.redirectToAuthPage(SecurityPolicyMode.Integrated);
      await new Promise(() => {
        // Noop
      });
    }

    const samlPolicy = Object.entries(this.securityPolicies).find(this.isSamlPolicy);

    if (
      this.isSamlPolicy(samlPolicy) &&
      samlPolicy[1].config.autoRedirect &&
      this.userContextPolicies[samlPolicy[0]] == null
    ) {
      this.redirectToAuthPage(SecurityPolicyMode.SAML);
      await new Promise(() => {
        // Noop
      });
    }

    return this.userContextPolicies;
  }

  async authorizeAsync(config: AuthorizeAction): Promise<AuthorizeResult> {
    if (this.userContextPolicies == null) {
      await this.getUserContextPolicies();
    }

    if (window.EB.auth.tokenExpiredPolices.includes(config.policy)) {
      // wait for user action on token expired confirm modal
      await new Promise<void>((resolve) => {
        const intervalId = setInterval(() => {
          if (!window.EB.auth.tokenExpiredPolices.includes(config.policy)) {
            clearInterval(intervalId);

            resolve();
          }
        }, 1000);
      });
    }

    return this.authorize(config);
  }

  private isIntegratedPolicy(
    policy: [string, SecurityPolicy] | undefined
  ): policy is [string, Extract<SecurityPolicy, { mode: SecurityPolicyMode.Integrated }>] {
    return policy?.[1].mode === SecurityPolicyMode.Integrated;
  }

  private isSamlPolicy(
    policy: [string, SecurityPolicy] | undefined
  ): policy is [string, Extract<SecurityPolicy, { mode: SecurityPolicyMode.SAML }>] {
    return policy?.[1].mode === SecurityPolicyMode.SAML;
  }

  private getOpenIdRedirectUrl(
    policyName: string,
    config: OpenIdConfig,
    returnUrl?: string
  ): string {
    let redirectUri = `${window.location.origin}${window.location.pathname}`;

    if (config?.authType !== 'AD') {
      redirectUri += `?sso_policy=${encodeURIComponent(policyName)}&sso_path=${encodeURIComponent(
        returnUrl ?? window.location.hash.substring(1)
      )}`;
    }

    return redirectUri;
  }

  private redirectToAuthPage(mode: SecurityPolicyMode.Integrated | SecurityPolicyMode.SAML) {
    const returnUrl = `${window.location.origin}${window.location.pathname}`;

    // TODO: Verify that `window.location.pathname` is good for domain alias
    window.location.href = `/${mode == SecurityPolicyMode.SAML ? 'saml' : 'negotiate'}${
      window.location.pathname
    }?return_url=${returnUrl}`;
  }

  private getOpenIdClientOAuth(policyName: string, config: OpenIdConfig, returnUrl?: string) {
    const redirectUrl = this.getOpenIdRedirectUrl(policyName, config, returnUrl);

    const oAuthSetting = {
      clientId: config.clientId,
      clientSecret: config.clientSecret,
      authorizationUri: config.authUri,
      accessTokenUri: config.tokenUri,
      redirectUri: redirectUrl,
      scopes: config.scope?.split(' ') ?? []
    };

    return new ClientOAuth2(oAuthSetting);
  }

  async requestLogin(policyName: string, path?: string): Promise<void> {
    const policy = this.securityPolicies[policyName];
    if (!policy) {
      return;
    }

    if (policy.mode === SecurityPolicyMode.OpenId) {
      const openIdConfig = policy.config;

      if (!openIdConfig) throw new Error('Missing openId config');

      const userAuth = this.getOpenIdClientOAuth(policyName, openIdConfig, path);
      const uri = userAuth[openIdConfig.authFlow ?? 'token'].getUri();

      window.location.href = uri;

      return new Promise(() => {
        // noop
      });
    }

    if (policy.mode === SecurityPolicyMode.Integrated || policy.mode === SecurityPolicyMode.SAML) {
      this.redirectToAuthPage(policy.mode);

      return new Promise(() => {
        // noop
      });
    }

    return new Promise<void>((resolve, reject) => {
      const policyLabel = this.securityPolicies[policyName].label;

      window.EB.emitter.emit('eb-open-login', {
        authorize: { policy: policyName },
        __modal_title__: window.EB.intl.formatMessage('__eb_user_login_title', {
          policy: policyLabel ? window.EB.intl.formatMessage(policyLabel) : policyName
        })
      });

      const unsubscribeEvents = () => {
        window.EB.emitter.off('eb-logged-in', onLoggedIn);
        window.EB.emitter.off('eb-cancel-login', onCancelLogin);
      };

      const onCancelLogin = () => {
        unsubscribeEvents();

        reject();
      };

      const onLoggedIn = () => {
        unsubscribeEvents();

        resolve();
      };

      window.EB.emitter.on('eb-logged-in', onLoggedIn);
      window.EB.emitter.on('eb-cancel-login', onCancelLogin);
    });
  }

  logout(policyName: string): void {
    const policy = this.securityPolicies[policyName];

    if (policy?.mode === SecurityPolicyMode.SAML) {
      window.location.href = `${window.location.origin}/samlout${
        window.location.pathname
      }?return_url=${encodeURIComponent(window.location.origin + window.location.pathname)}`;

      return;
    }

    const idToken = this.httpClient.getIdTokenOfPolicy(policyName);

    delete this.tokenExpiredPoliciesState[policyName];

    this.httpClient.removeAuthTokensForPolicy(policyName);

    if (this.userContextPolicies) {
      this.userContextPolicies[policyName] = null;
    }

    if (policy?.mode === SecurityPolicyMode.OpenId && policy.config && policy.config.logoutUri) {
      let logoutUri = policy.config.logoutUri;

      logoutUri += `?client_id=${policy.config.clientId}`;

      if (policy.config.authType === 'AD') {
        logoutUri += `&redirectUrl=${encodeURIComponent(
          window.location.origin + window.location.pathname
        )}`;
      } else {
        logoutUri += `&post_logout_redirect_uri=${encodeURIComponent(
          window.location.origin + window.location.pathname
        )}`;
      }

      if (idToken) {
        logoutUri += `&id_token_hint=${idToken}`;
      }

      window.location.href = logoutUri;

      return;
    }

    window.EB.emitter.emit('eb-route-redirect', { path: '/' });
    window.EB.emitter.emit('eb-logged-out', { policy: policyName });
  }

  async login(credentials: LoginCredentials): Promise<{
    success: boolean;
    displayName?: string;
  }> {
    try {
      const { accessToken, refreshToken } = await this.httpClient.post<{
        accessToken: string;
        refreshToken: string;
      }>('/v1/auth/login', credentials);

      const { family_name, given_name, exp } = jwtDecode<{
        given_name: string;
        family_name: string;
        exp: number;
      }>(accessToken);

      this.httpClient.updateAuthTokensForPolicies(
        credentials.policy,
        accessToken,
        refreshToken,
        exp
      );

      await this.getUserContextPolicies();

      delete this.tokenExpiredPoliciesState[credentials.policy];

      return {
        success: true,
        displayName: `${given_name ?? ''} ${family_name ?? ''}`.trim()
      };
    } catch (error) {
      return {
        success: false
      };
    }
  }

  tokenExpired = debounce(async () => {
    const policyName = Object.keys(this.tokenExpiredPoliciesState)[0];

    if (!policyName) {
      return;
    }

    const isOk = await window.EB.emitter.confirm(
      window.EB.intl.formatMessage('__eb_user_login_session_expired')
    );

    if (!isOk) {
      this.logout(policyName);

      return null;
    }

    await this.requestLogin(policyName);
  }, 1000);

  addTokenExpiredPolicy(policyName: string): void {
    if (this.tokenExpiredPoliciesState[policyName]) {
      return;
    }

    this.tokenExpiredPoliciesState[policyName] = true;

    this.tokenExpired();
  }

  async refreshToken(
    policyName: string,
    accessToken: string,
    refreshToken: string
  ): Promise<{
    exp: number;
    accessToken: string;
    refreshToken: string;
    idToken?: string;
  } | null> {
    const policy = this.securityPolicies[policyName];

    if (![SecurityPolicyMode.Identity, SecurityPolicyMode.OpenId].includes(policy?.mode)) {
      return null;
    }

    if (this.tokenExpiredPoliciesState[policyName]) {
      return null;
    }

    if (policy.mode === SecurityPolicyMode.OpenId) {
      // Open ID refreshing token
      const userOAuth = this.getOpenIdClientOAuth(policyName, policy.config);
      const token = userOAuth.createToken(accessToken, refreshToken, {});

      try {
        const newTokens: ClientOAuth2.Token = await token.refresh();

        return {
          exp: jwtDecode<{
            exp: number;
          }>(newTokens.accessToken).exp,
          accessToken: newTokens.accessToken,
          refreshToken: newTokens.refreshToken,
          idToken: newTokens.data['id_token']
        };
      } catch (error) {
        this.addTokenExpiredPolicy(policyName);
        return Promise.resolve(null);
      }
    }

    const refreshTokenResponse = await fetch(`${this.httpClient.baseUrl}/v1/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [`X-EB-AUTH-POLICY-${policyName}`]: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        refreshToken: refreshToken,
        policy: policyName
      })
    });

    if (refreshTokenResponse.status !== 200) {
      this.addTokenExpiredPolicy(policyName);
      return Promise.resolve(null);
    }

    const newTokens: {
      exp: number;
      accessToken: string;
      refreshToken: string;
    } = await refreshTokenResponse.json();

    const { exp } = jwtDecode<{
      exp: number;
    }>(newTokens.accessToken);

    return {
      exp,
      accessToken: newTokens.accessToken,
      refreshToken: newTokens.refreshToken
    };
  }

  private checkIfNotHavePermissions(
    expectedPems: Record<string, keyof typeof Permission>,
    userRoles: Record<string, RoleContext>
  ): boolean {
    return Object.entries(expectedPems).some(([expectedPemName, expectedPemValueStr]) => {
      const expectedPemValue = Permission[expectedPemValueStr];

      return Object.values(userRoles).every(
        ({ permissions: rolePermissions }) =>
          rolePermissions[expectedPemName] == null ||
          rolePermissions[expectedPemName] < expectedPemValue
      );
    });
  }

  authorize(authorizeAction?: AuthorizeAction): AuthorizeResult {
    if (!authorizeAction) {
      return AuthorizeResult.Allow;
    }

    const { policy, roles = [], permissions } = authorizeAction;
    const userContext = this.userContextPolicies?.[policy];

    if (!userContext) {
      switch (this.securityPolicies[policy]?.mode) {
        case SecurityPolicyMode.OpenId: {
          const openIdConfig = this.securityPolicies[policy]?.config;

          if (!openIdConfig) throw new Error('Missing openId config');

          // TODO: Figure out a way to do authentication automatically...

          return AuthorizeResult.LoginRequired;
        }
        case SecurityPolicyMode.Identity: {
          return AuthorizeResult.LoginRequired;
        }
        case SecurityPolicyMode.Integrated: {
          return AuthorizeResult.LoginRequired;
        }
        default: {
          return AuthorizeResult.Deny;
        }
      }
    }

    // Allow all actions with SecurityMode.None
    if (this.securityPolicies[policy]?.mode === SecurityPolicyMode.None) {
      return AuthorizeResult.Allow;
    }

    if (roles.length) {
      if (
        Object.keys(userContext.roles).every(
          (roleName) => !roles.some((expectedRole) => isMatch(roleName, expectedRole))
        )
      ) {
        return AuthorizeResult.Deny;
      }
    }

    if (permissions != null) {
      if (!Array.isArray(permissions)) {
        if (this.checkIfNotHavePermissions(permissions, userContext.roles)) {
          return AuthorizeResult.Deny;
        }
      } else if (
        permissions.every((subPem) => this.checkIfNotHavePermissions(subPem, userContext.roles))
      ) {
        return AuthorizeResult.Deny;
      }
    }

    return AuthorizeResult.Allow;
  }

  userContext(authorize: { policy: string }): UserContext | undefined | null {
    return this.userContextPolicies?.[authorize.policy];
  }
}
