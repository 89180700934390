import { AppType } from '../common';

export class EBAssets {
  constructor(private version: string, private appName: string, private appType: AppType) {}

  get(address: string): string {
    if (!address || /^https?:\/\//i.test(address)) {
      return address;
    }

    const lastIndexOfDot = address.lastIndexOf('.');
    const fileExt = address.substring(lastIndexOfDot + 1);
    const fileWithoutExt = address.substring(0, lastIndexOfDot);
    const url =
      `/generated/assets/${fileWithoutExt}${this.version}` + (fileExt ? `.${fileExt}` : '');

    if (this.appType === AppType.Static) {
      // we don't need to add app name for static app
      return url;
    }

    return `/${this.appName}${url}`;
  }
}
