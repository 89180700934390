export class EBTheme {
  private currentTheme: string;
  private supportedThemes: string[] = [];
  private storageCacheKey = '';

  constructor(
    private appName: string,
    private theme: Readonly<{
      default: string;
      support: string[];
      customThemes?: ({ name: string } & Record<string, object>)[] | null;
    }>
  ) {
    this.storageCacheKey = `__eb_theme_${appName}`;
    this.supportedThemes = theme.support;

    const initTheme = localStorage.getItem(this.storageCacheKey);

    if (!initTheme || !this.supportedThemes.includes(initTheme)) {
      this.currentTheme = theme.default || 'blue-macros';
    } else {
      this.currentTheme = initTheme;
    }
  }

  switchTheme(theme: string): void {
    if (!this.supportedThemes.includes(theme)) {
      return;
    }

    this.currentTheme = theme;
    localStorage.setItem(this.storageCacheKey, theme);

    location.reload();
  }

  /**
   * Get custom theme config, if theme doesn't exist, returns null
   * @param theme
   * @returns
   */
  getCustomThemeConfig(theme: string): object | null {
    const customThemeConfig = this.theme.customThemes?.find((x) => x.name === theme);

    if (!customThemeConfig) {
      console.error(`Not found custom theme '${theme}'`);
      return null;
    }

    return customThemeConfig;
  }

  get Themes(): string[] {
    return this.supportedThemes;
  }

  get CurrentTheme(): string {
    return this.currentTheme;
  }
}
