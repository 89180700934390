import objectHash from 'object-hash';

export class EBCache {
  private keysPerTags: Record<string, Set<string>> = {};

  get<T = unknown>(key: object): T | null {
    const strKey = `EB-cache-${objectHash(key)}`;

    return window.EB.storage.app().get<T>('page', strKey);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  save(key: object, data: any, tags: string[]) {
    const strKey = `EB-cache-${objectHash(key)}`;

    window.EB.storage.app().set('page', strKey, data);

    tags.forEach((tag) => {
      if (!tag) {
        return;
      }

      if (!this.keysPerTags[tag]) {
        this.keysPerTags[tag] = new Set();
      }

      this.keysPerTags[tag].add(strKey);
    });
  }

  invalidByKey(...keys: object[]) {
    keys.forEach((key) => {
      const strKey = `EB-cache-${objectHash(key)}`;

      window.EB.storage.app().delete('page', strKey);

      Object.values(this.keysPerTags).forEach((savedKeys) => {
        if (savedKeys.has(strKey)) {
          savedKeys.delete(strKey);
        }
      });
    });
  }

  invalidByTag(...tags: string[]) {
    tags.forEach((tag) => {
      if (!tag) {
        return;
      }

      if (!this.keysPerTags[tag]) {
        return;
      }

      this.keysPerTags[tag].forEach((strKey) => {
        window.EB.storage.app().delete('page', strKey);
      });
    });
  }
}
