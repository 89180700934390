const downloadBlob = (blob: Blob, name: string): void => {
  const blobUrl = URL.createObjectURL(blob);

  const link = document.createElement('a');

  link.href = blobUrl;
  link.download = name;
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );

  document.body.removeChild(link);

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    URL.revokeObjectURL(blobUrl);
    link.remove();
  }, 100);
};

export default downloadBlob;
