import { SecurityPolicy } from '../auth';

export enum AppType {
  Default = 'default',
  Static = 'static'
}

export type EBGlobalConfig = {
  engineUrl?: string;
  api: string;
  appName: string;
  appType: AppType;
  version: string;
  cacheVersion: string;
  /**
   *  { langCode: module path or langKeys object }
   */
  languages: Record<string, string | Record<string, string>>;
  externals?: Record<string, unknown>;
  constant?: Record<string, unknown>;
  securityPolicies: Record<string, SecurityPolicy>;
  theme: Readonly<{
    default: string;
    support: string[];
    customThemes?: ({ name: string } & Record<string, object>)[] | null;
  }>;
};
