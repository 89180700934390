import { AxiosResponse } from 'axios';
import lodashGet from 'lodash-es/get';

const ERROR_MESSAGE_IN_RESPONSE = [
  'result.data.error',
  'result.data.errorMessage',
  'result.error',
  'result.errorMessage',
  'error',
  'errorMessage'
];

export class EBAPIError extends Error {
  request: XMLHttpRequest | null = null;
  response: AxiosResponse | null = null;
  status: number | null = null;
  errorMessageInResponse: string | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  constructor(error: any) {
    super(error.message);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      this.response = error.response as AxiosResponse;
      this.status = this.response.status;

      // ignoring internal server errros
      if (this.response.data && this.status < 500) {
        // finding error message
        for (let index = 0; index < ERROR_MESSAGE_IN_RESPONSE.length; index++) {
          this.errorMessageInResponse = lodashGet(
            this.response.data,
            ERROR_MESSAGE_IN_RESPONSE[index]
          );

          if (this.errorMessageInResponse) {
            break;
          }
        }
      }
    }

    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      this.request = error.request;
    }

    // Added this to make **instanceof** work in Jest
    Object.setPrototypeOf(this, EBAPIError.prototype);
  }
}
