import objectHash from 'object-hash';
import { stringify } from 'qs';
import { HttpClient } from '../common';

export type QueryConfig = {
  name: QueryMutationId;
  params?: Record<string, unknown>;
  pagination?: QueryPagination | null;
  skip?: number;
  take?: number;
  orders?: QueryOrder[];
  filters?: QueryFilter[];
  cache?: boolean | string[];
};

export type ExcelQueryConfig = {
  name: QueryMutationId;
  params?: Record<string, unknown>;
  pagination?: QueryPagination | null;
  skip?: number;
  take?: number;
  orders?: QueryOrder[];
  filters?: QueryFilter[];
  columnNames?: Record<string, string>;
  sheetName?: string;
  fileName?: string;
  includeColumns?: string[];
};

export type QueryPagination = {
  page: number;
  size: number;
  total?: number;
};

export type QueryOrder = {
  by: string;
  direction: 'ASC' | 'DESC';
};

export type QueryMutationId = string | { datasource: string; name: string };

export enum QueryFilterOperator {
  In = 'in',
  NotIn = 'nin',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  Equals = 'e',
  NotEquals = 'ne',
  Contains = 'c',
  StartsWith = 'sw',
  NotContains = 'nc',
  Null = 'null',
  NotNull = 'notNull',
  NullOrEmpty = 'nullOrEmpty',
  NotNullOrEmpty = 'notNullOrEmpty',
  // for filtering by date only (.i.e ignore time)
  DateGT = 'date_gt',
  DateGTE = 'date_gte',
  DateLT = 'date_lt',
  DateLTE = 'date_lte',
  DateEquals = 'date_e',
  DateNotEquals = 'date_ne'
}

export type QueryFilter = {
  field: string;
  op: QueryFilterOperator;
  value: unknown;
};

export type MutationResponse<T> = {
  result: T;
};

export type ExcelResponse = {
  downloadKey: string;
  downloadName: string;
};

export type QueryResponse<T> = {
  pagination?: QueryPagination | null;
  results: T[];
  result?: T | null;
};

export type EBDatabaseQueryMock = (
  ...args: Parameters<EBDatabase['query']>
) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<QueryResponse<any>>;

export class EBDatabase {
  private __queryMock: Record<string, EBDatabaseQueryMock> | null = null;

  constructor(private httpClient: HttpClient) {}

  __AddQueryMock(mocks: { name: string; mock: EBDatabaseQueryMock }[]) {
    if (!import.meta.env.STORYBOOK || import.meta.env.NODE_ENV === 'production') {
      throw new Error('__queryMock can be used only in dev or storybook');
    }

    if (!this.__queryMock) {
      this.__queryMock = {};
    }

    mocks.forEach(({ name, mock }) => {
      this.__queryMock![name] = mock;
    });
  }

  async queryFromResult<T = unknown>(
    statementQueryName: string | null,
    statementQueryParams:
      | Record<string | number, unknown>
      | Record<string | number, unknown>[] = {},
    statementExecuteParams:
      | Record<string | number, unknown>
      | Record<string | number, unknown>[] = {},
    statementQueryOrders: QueryOrder[] = []
  ): Promise<QueryResponse<T>> {
    const url = `/v1/db/query/from/result`;

    return this.httpClient.post(url, {
      statementQueryName,
      statementQueryParams,
      statementExecuteParams,
      statementQueryOrders
    });
  }

  queryWithConfig<T = unknown>(queryConfig: QueryConfig): Promise<QueryResponse<T>> {
    const { name, params, pagination, orders, filters, cache, take, skip } = queryConfig;

    return this.query<T>(name, params, pagination, orders, filters, cache, take, skip);
  }

  /**
   * @deprecated use queryWithConfig instead
   */
  async query<T = unknown>(
    queryId: QueryMutationId,
    params: Record<string | number, unknown> | Record<string | number, unknown>[] = {},
    pagination: QueryPagination | null = null,
    orders: QueryOrder[] | undefined = undefined,
    filters: QueryFilter[] | undefined = undefined,
    cache: boolean | string[] = false,
    take: number | undefined = undefined,
    skip: number | undefined = undefined
  ): Promise<QueryResponse<T>> {
    if (this.__queryMock) {
      const name = typeof queryId === 'string' ? queryId : queryId.name;
      const mock = this.__queryMock[name];

      if (!mock) {
        console.error(`Query '${name}' has not been mocked`);
        return {
          pagination: null,
          results: []
        };
      }

      return mock(queryId, params, pagination, orders, filters, cache, take, skip);
    }

    const { page, size } = pagination ?? {};

    const resolvedOrders = orders
      ? orders.map(({ by, direction }) => `${by} ${direction ?? 'ASC'}`).join(',')
      : undefined;
    const resolvedFilters = filters?.map((filter) => {
      const { field, op, value } = filter;
      const base = { Field: field, Op: op };

      if ([QueryFilterOperator.In, QueryFilterOperator.NotIn].includes(op)) {
        return {
          ...base,
          Values: value
        };
      }

      return {
        ...base,
        Value: value
      };
    });

    const querystring = stringify({ page, size, take, skip }, { encode: false, allowDots: true });
    const url = `/v1/db/query/${
      typeof queryId === 'object' ? queryId.name : queryId
    }?${querystring}`;

    if (cache) {
      // take and skip are already in url
      const cached = window.EB.cache.get<QueryResponse<T>>({
        url,
        orders: resolvedOrders,
        filters: resolvedFilters,
        params,
        queryId
      });

      if (cached) {
        return Promise.resolve(cached);
      }
    }

    const response = await this.httpClient.post<QueryResponse<T>>(
      url,
      // take and skip are already in url
      {
        orders: resolvedOrders,
        filters: resolvedFilters,
        params
      },
      typeof queryId === 'object' ? { 'X-EB-DYNAMIC-DATASOURCE': queryId.datasource } : undefined
    );

    const cacheTagForQueryAndParams = objectHash({ url, params, queryId });
    window.EB.cache.invalidByTag(cacheTagForQueryAndParams);

    const cacheTags = Array.isArray(cache) ? cache : [];

    cacheTags.push(cacheTagForQueryAndParams);

    window.EB.cache.save(
      { url, orders: resolvedOrders, filters: resolvedFilters, params, queryId },
      response,
      cacheTags
    );

    return response;
  }

  /**
   * @deprecated use queryToExcelWithConfig instead
   */
  async queryToExcel(
    queryId: QueryMutationId,
    params: Record<string | number, unknown> | Record<string | number, unknown>[] = {},
    pagination: QueryPagination | null = null,
    orders: QueryOrder[] | undefined = undefined,
    filters: QueryFilter[] | undefined = undefined,
    columnNames: Record<string, string> | undefined = undefined,
    sheetName: string | undefined = undefined,
    fileName: string | undefined = undefined,
    includeColumns: string[] | undefined = undefined,
    take: number | undefined = undefined,
    skip: number | undefined = undefined
  ): Promise<ExcelResponse> {
    const { page, size } = pagination ?? {};

    const resolvedOrders = orders
      ? orders.map(({ by, direction }) => `${by} ${direction ?? 'ASC'}`).join(',')
      : undefined;
    const resolvedFilters = filters?.map((filter) => {
      const { field, op, value } = filter;
      const base = { Field: field, Op: op };

      if ([QueryFilterOperator.In, QueryFilterOperator.NotIn].includes(op)) {
        return {
          ...base,
          Values: value
        };
      }

      return {
        ...base,
        Value: value
      };
    });

    const querystring = stringify({ page, size, take, skip }, { encode: false, allowDots: true });
    const url = `/v1/db/query/${
      typeof queryId === 'object' ? queryId.name : queryId
    }/excel?${querystring}`;

    return this.httpClient.post(
      url,
      {
        orders: resolvedOrders,
        filters: resolvedFilters,
        params,
        sheetName,
        fileName,
        columnNames,
        includeColumns
      },
      typeof queryId === 'object' ? { 'X-EB-DYNAMIC-DATASOURCE': queryId.datasource } : undefined
    );
  }

  queryToExcelWithConfig(queryConfig: ExcelQueryConfig): Promise<ExcelResponse> {
    const {
      name,
      params,
      pagination,
      orders,
      filters,
      columnNames,
      sheetName,
      fileName,
      includeColumns,
      take,
      skip
    } = queryConfig;

    return this.queryToExcel(
      name,
      params,
      pagination,
      orders,
      filters,
      columnNames,
      sheetName,
      fileName,
      includeColumns,
      take,
      skip
    );
  }

  async mutation<T = unknown>(
    mutationId: QueryMutationId,
    params: Record<string | number, unknown> | Record<string | number, unknown>[] = {},
    cache: { invalid?: string[] } = {}
  ): Promise<MutationResponse<T>> {
    const url = `/v1/db/mutation/${typeof mutationId === 'object' ? mutationId.name : mutationId}?`;

    const response = await this.httpClient.post<MutationResponse<T>>(
      url,
      params,
      typeof mutationId === 'object'
        ? { 'X-EB-DYNAMIC-DATASOURCE': mutationId.datasource }
        : undefined
    );

    if (cache.invalid?.length) {
      window.EB.cache.invalidByTag(...cache.invalid);
    }

    return response;
  }
}
