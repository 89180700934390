import datetime from 'moment';
import downloadBlob from './download-blob';
import openLink from './open-link';
import uuid from './uuid';
import { clone } from './clone';
import { getFileSizeString } from './fileSize';

export default {
  downloadBlob,
  openLink,
  uuid,
  datetime,
  getFileSizeString,
  clone
};
