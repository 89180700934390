const openLink = (
  url: string,
  options?: {
    newTab?: boolean;
    downloadName?: string;
    revokeObjectURL?: boolean;
  }
): void => {
  const { downloadName, newTab = false, revokeObjectURL = false } = options ?? {};

  if (!newTab && !downloadName) {
    window.location.href = url;
  }

  const link = document.createElement('a');

  link.href = url;
  link.download = downloadName ?? '';

  if (newTab) {
    link.target = '_blank';
  }

  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );

  document.body.removeChild(link);

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    revokeObjectURL && URL.revokeObjectURL(url);
    link.remove();
  }, 100);
};

export default openLink;
