import cloneDeep from 'lodash-es/cloneDeep';

export const clone = <T>(obj: T): T => {
  if (typeof obj !== 'object') {
    return obj;
  }
  if ('Vue' in window) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return cloneDeep((window as any).Vue.toRaw(obj));
  }

  return cloneDeep(obj);
};
