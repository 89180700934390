import { cloneDeep } from 'lodash-es';
import {
  IEBStorage as IEBStorageScope,
  IEBStorageType,
  EBStorageType,
  IEBStateStorage
} from './types';

export * from './types';

class PageStorage implements IEBStorageType {
  private caches: Record<string, unknown> = {};

  getItem(key: string): unknown {
    if (!this.caches[key]) {
      return null;
    }

    // return a cloned object to make sure cache is immutable
    return cloneDeep(this.caches[key]);
  }
  deleteItem(key: string) {
    delete this.caches[key];
  }
  setItem(key: string, value: unknown) {
    // return a cloned object to make sure cache is immutable
    this.caches[key] = cloneDeep(value);
  }
}

class JsonParserStorage implements IEBStorageType {
  constructor(private storage: Storage) {}

  getItem(key: string): unknown {
    const raw = this.storage.getItem(key) as string | null;

    if (!raw) {
      return null;
    }

    try {
      return JSON.parse(raw);
    } catch (error) {
      console.error(`Couldn't parse ${key}`);
      this.storage.removeItem(key);
    }

    return null;
  }
  deleteItem(key: string) {
    this.storage.removeItem(key);
  }
  setItem(key: string, value: unknown) {
    this.storage.setItem(key, JSON.stringify(value));
  }
}

const storages: Record<EBStorageType, IEBStorageType> = {
  local: new JsonParserStorage(localStorage),
  session: new JsonParserStorage(sessionStorage),
  page: new PageStorage()
};

class GlobalScopeStorage implements IEBStorageScope {
  delete(type: EBStorageType, key: string): void {
    storages[type].deleteItem(key);
  }
  get<T>(type: EBStorageType, key: string): T | null {
    return storages[type].getItem(key) as T | null;
  }
  set(type: EBStorageType, key: string, value: unknown): void {
    storages[type].setItem(key, value);
  }
}

class AppScopeStorage implements IEBStorageScope {
  getRaw(type: EBStorageType, key: string): string | null {
    const cacheKey = `${window.EB.appName}-${key}`;

    return storages[type].getItem(cacheKey) as string | null;
  }

  get<T>(type: EBStorageType, key: string): T | null {
    const cacheKey = `${window.EB.appName}-${key}`;

    return storages[type].getItem(cacheKey) as T | null;
  }
  set(type: EBStorageType, key: string, value: unknown): void {
    const cacheKey = `${window.EB.appName}-${key}`;

    storages[type].setItem(cacheKey, value);
  }
  delete(type: EBStorageType, key: string): void {
    const cacheKey = `${window.EB.appName}-${key}`;

    storages[type].deleteItem(cacheKey);
  }
}

class PolicyScopeStorage implements IEBStorageScope {
  constructor(private policy: string, private userId: string) {}

  getRaw(type: EBStorageType, key: string): string | null {
    const cacheKey = `${window.EB.appName}-${this.policy}-${this.userId}-${key}`;

    return storages[type].getItem(cacheKey) as string | null;
  }

  get<T>(type: EBStorageType, key: string): T | null {
    const cacheKey = `${window.EB.appName}-${this.policy}-${this.userId}-${key}`;

    return storages[type].getItem(cacheKey) as T | null;
  }
  set(type: EBStorageType, key: string, value: unknown): void {
    const cacheKey = `${window.EB.appName}-${this.policy}-${this.userId}-${key}`;

    storages[type].setItem(cacheKey, value);
  }
  delete(type: EBStorageType, key: string): void {
    const cacheKey = `${window.EB.appName}-${this.policy}-${this.userId}-${key}`;

    storages[type].deleteItem(cacheKey);
  }
}

class NoopStorage implements IEBStorageScope {
  getRaw(_type: EBStorageType, _key: string): string | null {
    return null;
  }

  get<T>(_type: EBStorageType, _key: string): T | null {
    return null;
  }

  delete(_type: EBStorageType, _key: string) {
    // noop
  }

  set(_type: EBStorageType, _key: string, _value: unknown): void {
    // noop
  }
}

export class EBStorage {
  private globalStorage: GlobalScopeStorage;
  private appStorage: AppScopeStorage;
  private noopStorage: NoopStorage;

  constructor() {
    this.globalStorage = new GlobalScopeStorage();
    this.noopStorage = new NoopStorage();
    this.appStorage = new AppScopeStorage();
  }

  /**
   * Global scoped storage
   */
  global(): IEBStorageScope {
    return this.globalStorage;
  }

  /**
   * App scoped storage
   */
  app(): IEBStorageScope {
    return this.appStorage;
  }

  /**
   * User scoped storage
   */
  policy(policy: string): IEBStorageScope {
    const userContext = window.EB.auth.userContext({ policy });

    if (!userContext) {
      return this.noopStorage;
    }

    return new PolicyScopeStorage(policy, userContext.id);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  public stateStore: IEBStateStorage = { getStore: () => ({} as any), removeStore: () => {} };
}
